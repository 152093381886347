//$primary:darken(#3f305b,0%);
$primary:darken(#428bca, 0%);

@import '~bootstrap/scss/bootstrap.scss';
h1{
  font-weight: lighter;
  margin-bottom: 20px;
  font-size: 28px;
  @include media-breakpoint-down(sm)
  {
    font-size: 28px;
  }
}
.nav-link{
  color: black !important;
}


  @include media-breakpoint-up(lg) {
    .sign-up{
    border-radius: 30px;
    min-width: 120px;
    padding: 10px;
    border: 1px solid $primary;
    margin-left: 10px;

  }
    a.sign-up{
      color: $primary !important;
      @include media-breakpoint-up(lg) {
        padding-left: 1rem !important;
      }

    }
}
.navbar-brand{
  width: 60px;
}
.footer{

  margin-top: 50px;
  .copyright{
    margin-bottom: 10px;
  }
}

.btn-cta{
  min-width: 200px;
}

.navbar-brand{
  width: 100px !important;
  height: auto !important;
  margin: 0 !important;
  img{
    width: 100px;
    height: auto;
  }
}
main{
  min-height: 80vh;
  padding: 0;
  margin: 0 !important;
}
.home-splash
{
  background-color: $primary;
  background-position: top center;
  background-image: url("./assets/images/main.jpg");
  background-size: cover;
  width: 100%;
  height: 400px;
  @include media-breakpoint-up(md) {
    height: 500px;
  }
  @include media-breakpoint-up(lg) {
    height: 640px;
  }
}
.card-title
{
  color: $primary;
}
.bg-mid {
  background-color: $primary;
}
  .home {
    .card {
      @include media-breakpoint-up(sm) {
        min-height: 660px;
      }
    }
  }

.text-shadow {

  text-shadow: 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px rgba(0,0,0,0.12), 0 1px 5px rgba(0,0,0,0.2)
}